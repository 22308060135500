import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Subscribe from "../components/common/Subscribe";

import { Layout, PostCard, IconCard, SideCard } from "../components/common/";
import { MetaData } from "../components/common/meta";
import ContentContext from "../components/common/ContentContext";
import { EdgeTagFilter } from "../components/common/UrlFormater";
import { TagCatcher } from "../components/common/TagManipulation";
/**
 * Main index page (home page)
 *
 * Loads all posts from Ghost and uses pagination to navigate through them.
 * The number of posts that should appear per page can be setup
 * in /utils/siteConfig.js under `postsPerPage`.
 *
 */

//take properties from context and filter appropriatelly the content

const Index = ({ data, location }) => {
    const [state, setState] = useContext(ContentContext);

    useEffect(() => {
        setState({ ...state, ...TagCatcher(data.ghostPage.tags) });
    }, []);

    let row1 = EdgeTagFilter(data.allGhostPost.edges, [
        state.lng,
        state.mkt,
        state.sct,
        `#ren@fr1`,
    ]).slice(0, 3);
    let row2 = EdgeTagFilter(data.allGhostPost.edges, [
        state.lng,
        state.mkt,
        state.sct,
        `Ανακαίνιση`,
        `#ren@frontpage`,
    ]).slice(0, 3);
    let row3 = EdgeTagFilter(data.allGhostPost.edges, [
        state.lng,
        state.mkt,
        state.sct,
        `#ren@fr2`,
    ]).slice(0, 4);
    let row4 = EdgeTagFilter(data.allGhostPost.edges, [
        state.lng,
        state.mkt,
        state.sct,
        `Υπηρεσίες`,
        `#ren@frontpage`,
    ]).slice(0, 3);
    let row5 = EdgeTagFilter(data.allGhostPost.edges, [
        state.lng,
        state.mkt,
        state.sct,
        `#ren@fr3`,
    ]).slice(0, 2);
    let row6 = EdgeTagFilter(data.allGhostPost.edges, [
        state.lng,
        state.mkt,
        state.sct,
        `#ren@fr4`,
    ]).slice(0, 2);
    // console.log(data.ghostPage)
    // console.log(state)
    // console.log(data.allGhostPost.edges)
    // console.log(row1)
    return (
        <>
            <MetaData
                location={location}
                description={data.ghostPage.meta_description}
                title={data.ghostPage.meta_title}
            />
            <Layout isHome={true} pageData={data}>
                <div className="container">
                    <section className="thirds">
                        {row1.map(({ node }) => (
                            // The tag below includes the markup for each post - components/common/PostCard.js
                            <SideCard key={node.id} post={node} />
                        ))}
                    </section>
                    {row2.length > 0 && <hr />}
                    <section className="post-feed">
                        {row2.map(({ node }) => (
                            // The tag below includes the markup for each post - components/common/PostCard.js
                            <PostCard key={node.id} post={node} />
                        ))}
                    </section>
                    {row3.length > 0 && <hr />}
                    <section className="quarters">
                        {row3.map(({ node }) => (
                            // The tag below includes the markup for each post - components/common/PostCard.js
                            <IconCard key={node.id} post={node} />
                        ))}
                    </section>
                    {row4.length > 0 && <hr />}
                    <section className="post-feed">
                        {row4.map(({ node }) => (
                            // The tag below includes the markup for each post - components/common/PostCard.js
                            <PostCard key={node.id} post={node} />
                        ))}
                    </section>
                    <section className="thirds">
                        {row5.map(({ node }) => (
                            // The tag below includes the markup for each post - components/common/PostCard.js
                            <IconCard key={node.id} post={node} />
                        ))}
                    </section>
                    <section className="post-feed">
                        {row6.map(({ node }) => (
                            // The tag below includes the markup for each post - components/common/PostCard.js
                            <PostCard key={node.id} post={node} />
                        ))}
                    </section>
                    {false && <Subscribe />}
                </div>
            </Layout>
        </>
    );
};

// Index.propTypes = {
//     data: PropTypes.shape({
//         allGhostPost: PropTypes.object.isRequired,
//     }).isRequired,
//     location: PropTypes.shape({
//         pathname: PropTypes.string.isRequired,
//     }).isRequired,
//     pageContext: PropTypes.object,
// }

Index.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
        allGhostSettings: PropTypes.object.isRequired,
        ghostPage: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            // html: PropTypes.string.isRequired,
            feature_image1: PropTypes.object,
            tags: PropTypes.array,
        }).isRequired,
    }).isRequired,
    //location: PropTypes.object.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
};

export default Index;

// // This page query loads all posts sorted descending by published date
// // The `limit` and `skip` values are used for pagination
// export const pageQuery = graphql`
//   query GhostPostQuery($limit: Int!, $skip: Int!) {
//     allGhostPost(
//         sort: { order: DESC, fields: [published_at] },
//         limit: $limit,
//         skip: $skip
//     ) {
//       edges {
//         node {
//           ...GhostPostFields
//         }
//       }
//     }
//   }
// `

export const pageQuery = graphql`
    query($slug: String!) {
        ghostPage(slug: { eq: $slug }) {
            ...GhostPageFields
        }
        allGhostPost(sort: { order: DESC, fields: [published_at] }) {
            edges {
                node {
                    ...GhostPostFields
                }
            }
        }
        allGhostSettings {
            edges {
                node {
                    ...GhostSettingsFields
                }
            }
        }
    }
`;
