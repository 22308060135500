// takes a tag array and returns an organized tagMap with tags returned in hole
const TagCatcher = (tags) => {
    const tagSeperator = RegExp(`@`)
    const hash = RegExp(`#`)
    const tagsObj = {}
    tags.map((tag) => {
        const tagSet = tag.name.split(tagSeperator)
        tagSet[0] = tagSet[0].replace(hash, ``)
        tagsObj[tagSet[0]] = tag.name
    })
    return tagsObj
}

export { TagCatcher }
